@import "../../../style/basic/config.module";
@import"include-media";


$breakpoints: ('phone': 300px,
    'tablet': 768px,
    'desktop': 1024px,
) !default;



@include media(">=20px") {
    .basicsPage-grid-container {
        margin-top: 64px;
        display: flex;
        flex-direction: column;


        section {
            margin-top: 64px;
        }

        .duration {
            position: relative;

            .durationIcon {
                display: block;
                margin: 0 auto;

                width: 320px;
                height: 105px;

                margin-top: 64px;
                margin-bottom: -24px;
            }
        }

        h2 {
            font-size: 24px;
            font-weight: 700;
            text-transform: uppercase;
            color: $blueFour;
            padding: 0 24px;
        }

        p {
            font-size: 16px;
            font-weight: 600;
            color: $blueFour;
            padding: 0 24px;
        }

        p+p {
            margin-top: 24px;
            margin-bottom: -32px;
        }



        .next-page-container {
            margin-top: 64px;
        }

    }
}


@include media(">=tablet") {
    .basicsPage-grid-container {
        margin: 64px auto;


        section {
            width: 100%;
            margin: 32px auto;
        }

        .duration {

            margin: 16px auto;

            .durationIcon {


                width: 600px;
                height: 300px;

                margin-top: 16px;
                margin-bottom: -100px;
            }
        }

        h2 {
            font-size: 48px;
            padding: 0 96px;
            margin-bottom: 8px;
        }

        p {
            font-size: 24px;
            padding: 0 96px;
            line-height: 36px;
        }

        p+p {
            margin-top: 32px;
            margin-bottom: -64px;
        }

        .mt {
            margin-top: 128px;
        }

        .next-page-container {
            margin-top: 192px;
        }

    }


}


@include media(">=desktop") {
    .basicsPage-grid-container {
        // margin: 64px auto;

        section {
            width: 85%;
            margin: 96px auto;
        }

        .duration {

            .durationIcon {


                width: 1000px;
                height: 500px;

                // margin-top: 64px;
                margin-bottom: -200px;
            }
        }

        h2 {
            // font-size: 48px;
            padding: 0 192px;
            // margin-bottom: 8px;
        }

        p {
            // font-size: 24px;
            padding: 0 192px;
            // line-height: 36px;
        }

        p+p {
            // margin-top: 64px;
            // margin-bottom: -64px;
        }

        .mt {
            // margin-top: 128px;
        }

        .next-page-container {
            // margin-top: 192px;
        }

    }


}


//!!=========================

// // //==========IPHONE SE && smaller========
// @include media(">20px", "<desktop") {
//     .basicsPage-grid-container {
//         margin-top: 64px;

//         section {
//             margin-top: 64px;
//         }

//         h2 {
//             font-size: 24px;
//             font-weight: 700;
//             text-transform: uppercase;
//             color: $blueFour;
//             padding: 0 24px;
//         }

//         p {
//             font-size: 16px;
//             font-weight: 600;
//             color: $blueFour;
//             padding: 0 24px;
//         }

//         p+p {
//             margin-top: 24px;
//             margin-bottom: -32px;
//         }



//         .next-page-container {
//             margin-top: 64px;
//         }

//     }
// }