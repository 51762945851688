@import "../style/basic/config.module";

div {
    .overlay {
        display: block;
        position: fixed;
        z-index: 888;
        width: 100vw;
        height: 100vh;

        background-color: $blueThree;

        pointer-events: none;

        // filter: blur(40px);


        // box-shadow: inset $blueFour 0 0 150px -35px;

        .logo-transition {
            // width: 100%;
            // height: 100%;

            position: absolute;
            transform: translate(-50%, -50%);
            transform-origin: top;
            left: 50%;
            top: 50%;
            display: block;
            margin: 0 auto;

            width: 50%;
            height: 50%;
            opacity: .5;

        }
    }

}