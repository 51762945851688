@import "../../style/basic/config.module";
@import"include-media";


$breakpoints: ('phone': 300px,
    'tablet': 768px,
    'desktop': 1024px,
) !default;



@include media(">20px") {
    .card-container {
        background-color: $blueFour;
        margin: 24px 12px;
        padding: 64px 24px 24px 24px;
        border-radius: 10px;

        a {
            .icon {
                display: block;
                margin: 0 auto;
                width: 192px;
                height: 192px;
            }
        }

        .content-wrap {
            color: $white;
            margin-top: 48px;

            .headline {
                font-size: 24px;
                font-weight: 700;
                text-transform: uppercase;
            }

            .description {
                font-size: 16px;
                font-weight: 600;

                margin-top: 8px;
            }

            .read-more-wrap {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;

                margin-top: 32px;

                a {
                    color: $greyThree;

                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    .arrow {
                        margin-left: 4px;
                    }

                    &:hover {
                        color: $greyTwo;
                    }

                }


            }
        }
    }
}



@include media(">=tablet") {
    .card-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        width: 85%;
        margin: 0 auto;

        margin-top: 96px;
        padding: 96px 46px;

        border-radius: 20px;

        a {
            margin: 0 auto;

            .icon {
                width: 256px;
                height: 256px;
            }
        }

        .content-wrap {
            width: 50%;
            margin-left: 24px;


            .headline {
                font-size: 36px;
            }

            .description {
                font-size: 24px;
            }

            .read-more-wrap {

                justify-content: flex-start;
                margin-top: 64px;

                a {
                    font-size: 24px;
                    margin: 0;

                    .arrow {
                        width: 24px;
                        height: 24px;
                        margin-top: 4px;
                        margin-left: 4px;
                    }

                }


            }
        }
    }
}


@include media(">=desktop") {
    .card-container {
        // display: flex;
        // flex-direction: row;
        // justify-content: space-between;
        // align-items: center;

        width: 65%;
        max-width: 1080px;

        // margin: 0 auto;

        margin-top: 128px;
        padding: 128px 46px;

        // border-radius: 20px;

        // a {
        //     margin: 0 auto;

        //     .icon {
        //         width: 256px;
        //         height: 256px;
        //     }
        // }

        // .content-wrap {
        //     width: 50%;
        //     margin-left: 24px;


        //     .headline {
        //         font-size: 36px;
        //     }

        //     .description {
        //         font-size: 24px;
        //     }

        //     .read-more-wrap {

        //         justify-content: flex-start;
        //         margin-top: 64px;

        //         a {
        //             font-size: 24px;
        //             margin: 0;
        //         }

        //         .arrow {
        //             width: 24px;
        //             height: 24px;
        //             margin-top: 4px;
        //             margin-left: 4px;
        //         }
        //     }
        // }
    }
}