@import "../../../../../style/basic/config.module";
@import "../../../../../style/basic/hover";
@import"include-media";


$breakpoints: ('phone': 300px,
    'tablet': 768px,
    'desktop': 1024px,
) !default;



@include media(">20px") {
    .contact-container {
        margin-top: 64px;

        h3 {
            font-size: 24px;
            font-weight: 700;
            text-transform: uppercase;
            text-align: center;
            color: $blueFour;
        }

        p {
            font-size: 16px;
            font-weight: 600;
            color: $blueFour;
            padding: 0 24px;
        }

        .contact-icons {
            display: flex;
            flex-direction: row;
            justify-content: center;


            margin-top: 16px;

            a {
                margin-right: 16px;
            }

            :last-child {
                margin-right: 0;
            }

        }
    }
}

@include media(">=tablet") {
    .contact-container {
        margin-top: 128px;

        h3 {
            font-size: 48px;
        }

        p {
            font-size: 28px;
            // line-height: 54px;
            font-weight: 600;


            width: 80%;

            display: block;
            margin: 32px auto;

        }

        .contact-icons {
            margin-top: 64px;

            a {
                margin-right: 64px;

                .icon {
                    width: 96px;
                    height: 96px;
                }
            }

            :last-child {
                margin-right: 0;
            }

        }
    }
}

@include media(">desktop") {
    .contact-container {
        // margin-top: 128px;

        h3 {
            // font-size: 48px;
        }

        p {
            font-size: 30px;
            line-height: 1.5;
            text-align: center;
            // font-weight: 600;


            width: 70%;

            // display: block;
            // margin: 32px auto;

        }

        .contact-icons {
            // margin-top: 64px;

            a {
                // margin-right: 64px;

                .icon {
                    width: 128px;
                    height: 128px;
                }
            }

            :last-child {
                // margin-right: 0;
            }

        }
    }
}