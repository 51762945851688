@import "../../../style/basic/config.module";
@import"include-media";

$breakpoints: ('phone': 300px,
    'tablet': 768px,
    'desktop': 1024px,
) !default;


@include media(">=20px") {
    .types-grid-container {
        display: flex;
        flex-direction: column;

        margin: 0 auto;


        .animation-wrap {
            width: 256px;
            margin: 0 auto;

            margin-top: 64px;


            .animation-container {}



            p {
                color: $blueFour;
                font-weight: 700;
                text-align: center;
                margin-top: 12px;
            }
        }

    }
}


@include media(">=tablet") {
    .types-grid-container {

        flex-direction: row;
        flex-wrap: wrap;

        width: 100%;

        padding: 0 64px;


        .animation-wrap {
            margin-top: 128px;

            // margin-right: 96px;
        }

        p {
            display: block;
            padding: 0;
        }

    }
}


@include media(">=desktop") {
    .types-grid-container {

        // flex-direction: row;
        // flex-wrap: wrap;

        width: 80%;
        padding: 0;


        .animation-wrap {
            // margin-top: 128px;
            margin-right: 96px;
        }

        p {
            // display: block;
            // padding: 0;
        }

    }
}




//=======IPHONE SE && smaller===========


// @include media(">20px", "<desktop") {
//     .types-grid-container {
//         display: flex;
//         flex-direction: column;

//         margin: 0 auto;


//         .animation-wrap {
//             width: 256px;
//             margin: 0 auto;

//             margin-top: 64px;


//             .animation-container {}



//             p {
//                 color: $blueThree;
//                 font-weight: 700;
//                 text-align: center;
//                 margin-top: 12px;
//             }
//         }

//     }
// }