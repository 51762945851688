@import "../../../style/basic/config.module";
@import"include-media";

$breakpoints: ('phone': 300px,
    'tablet': 768px,
    'desktop': 1024px,
) !default;


@include media(">=20px") {

    .easing-grid-container {
        display: flex;
        flex-direction: column;

        margin: 0 auto;



        .animation-wrap {

            margin: 0 auto;
            margin-top: 64px;

            padding: 0 0px;


            .animation-container {
                width: 300px;
            }



            p {
                color: $blueFour;
                font-size: 16px;
                font-weight: 700;
                text-align: center;
                margin-top: 4px;
            }
        }

    }

}


@include media(">=tablet") {
    .easing-grid-container {

        display: block;

        .animation-wrap {
            display: flex;
            flex-direction: row;

            justify-content: center;
            align-items: center;

            margin-top: 64px;

            padding: 0 96px;



            .animation-container {
                // height: 192px;


                width: 70%;
                padding: 0;
                // margin-left: 96px;

            }



            p {


                width: 100%;
                text-align: left;

                font-size: 36px;
                font-weight: 700;

                margin-top: 0;
                padding: 0;
                margin-left: -96px;



            }
        }

    }
}


@include media(">=desktop") {
    .easing-grid-container {

        // display: block;

        .animation-wrap {
            // display: flex;
            // flex-direction: row;

            // justify-content: center;
            // align-items: center;

            // margin-top: 64px;

            padding: 0 192px;



            .animation-container {
                // height: 192px;


                // width: 70%;
                // padding: 0;
                // margin-left: 96px;

            }



            p {


                width: 60%;
                // text-align: left;

                // font-size: 36px;
                // font-weight: 700;

                // margin-top: 0;
                // padding: 0;
                // margin-left: -96px;



            }
        }

    }
}



//===================IPHONE SE && smaller=========



// @include media(">20px", "<desktop") {

//     .easing-grid-container {
//         display: flex;
//         flex-direction: column;

//         margin: 0 auto;



//         .animation-wrap {

//             margin: 0 auto;
//             margin-top: 64px;

//             padding: 0 24px;


//             .animation-container {
//                 max-width: 327px;
//             }



//             p {
//                 color: $blueThree;
//                 font-size: 16px;
//                 font-weight: 700;
//                 text-align: center;
//                 margin-top: 4px;
//             }
//         }

//     }

// }