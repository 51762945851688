@import "../../../../../style/basic/config.module";
@import"include-media";


$breakpoints: ('phone': 300px,
    'tablet': 768px,
    'desktop': 1024px,
) !default;



@include media(">20px") {
    .reference-container {
        overflow: hidden;
        margin-top: 64px;
        padding: 0 24px;

        h3 {
            font-size: 24px;
            font-weight: 700;
            text-transform: uppercase;
            text-align: center;
            color: $blueFour;
            margin-bottom: -24px;
        }


        section {
            margin-top: 32px;

            padding: 24px;
            background-color: $blueFour;
            border-radius: 15px;
            font-weight: 700;



            ol {
                list-style: none;
                text-align: center;
                margin-top: 8px;

                li {
                    margin-top: 32px;
                    line-height: 1.3;

                    a {
                        display: block;
                        min-height: 128px;


                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;


                        color: $greyTwo;
                        font-weight: 600;

                        // text-decoration: underline;

                        background-color: $blueThree;
                        border-radius: 10px;

                        padding: 0 12px;

                        br{
                            margin-top: 38px;
                        }

                        div{
                            font-weight: 700;
                            margin-bottom: -20px;
                            color: $white;


                        }

                        &:hover{
                            color: $white;
                            background-color: lighten($blueThree, 4%);
                        }

                    }
                }

                li:first-child {
                    margin-top: 0;
                }
            }

        }
    }
}


@include media(">=tablet") {

    .reference-container {
        margin-top: 128px;

        h3 {
            font-size: 48px;
            margin-bottom: 32px;
        }


        section {
            font-size: 24px;
            margin-top: 128px;


            // padding: 48px 96px;
            padding: 64px;


            margin: 0 auto;
            width: 80%;

            // display: flex;
            // flex-direction: column;
            // justify-content: flex-start;


            ol {
                // display: flex;
                // flex-direction: row;
                // flex-wrap: wrap;

                // justify-content: flex-start;
                // align-items: flex-start;

                // // display: inline-block;
                // text-align: left;


                li {

                    // width: 100%;
                    // flex-basis: 50%;

                    // font-size: 30px;


                    // align-self: baseline;
                    // padding: 24px;


                    // text-align: center;

                    margin-top: 64px;


                    a{


                        min-height: 300px;
                        border-radius: 20px;
                        padding: 24px;
                        div{
                           margin-bottom: -28px;

                        //    color: red;

                            
                        }
                    }
                }

                li:first-child {
                    margin-top: 0px;
                }
            }
        }
    }
}


@include media(">desktop") {

    .reference-container {
        margin-top: 128px;

        h3 {
            font-size: 48px;
            margin-bottom: 32px;
        }


        section {
            font-size: 24px;
            margin-top: 128px;


            padding: 48px 96px;


            margin: 0 auto;
            width: 80%;

            display: flex;
            flex-direction: column;
            justify-content: center;


            ol {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                justify-content: flex-start;
                align-items: flex-start;

                // display: inline-block;
                text-align: left;


                li {

                    width: 100%;
                    flex-basis: 50%;

                    font-size: 30px;


                    align-self: baseline;
                    padding: 24px;


                    text-align: center;

                    margin-top: 32px;

                    a{
            justify-content: baseline;
            margin: 0 auto;

                        div{
                           margin-bottom: -38px;

                        }
                    }
                }

                li:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}