@import "../../style/basic/config.module";
@import "../../style/basic/hover";
@import"include-media";


$breakpoints: ('phone': 300px,
    'tablet': 768px,
    'desktop': 1024px,
) !default;


.navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;

    color: $blueFour;

    .menu-btn {
        width: 36px;
        height: 36px;
        cursor: pointer;
    }

    .info {
        color: $blueFour;
        text-transform: uppercase;

    }
}



@include media(">10px", "<601px") {
    .navbar {
        z-index: 999;

        .menu-btn {
            z-index: 20;
            display: block;
            margin-top: 24px;
            margin-left: 24px;
        }

        .menu-bg {
            z-index: 9;
            background-color: $blueThree;
            display: block;
            position: absolute;
            width: 100vw;
            height: 100vh;
        }

        .info {
            margin-top: 36px;
            margin-right: 24px;
        }

        .menu-container-closed {
            display: none;


        }

        .menu-container-open {
            overflow: hidden;

            display: block;

            z-index: 10;
            position: relative;
            position: absolute;
            // background-color: $blueThree;
            width: 100vw;
            height: 100vh;

            .animation-container {
                z-index: 9;
                position: absolute;
                bottom: 20%;
                transform: rotate(-120deg) scale(3);
                opacity: .5;
            }

            ol {
                z-index: 20;
                position: relative;
                list-style: none;
                display: flex;
                flex-direction: column;

                width: 50%;
                margin: 0 auto;
                margin-top: 40%;

                li {
                    margin-bottom: 64px;


                    a {


                        width: fit-content;
                        font-weight: 700;
                        font-size: 36px;
                        line-height: 39.6px;
                        text-transform: uppercase;
                        color: $white;



                    }

                }

                :last-child {
                    margin-bottom: 0;
                }
            }

        }
    }
}

@include media(">600px") {
    .navbar {

        .menu-btn {
            display: none;
        }

        .info {
            margin-top: 36px;
            margin-right: 24px;

            p {
                color: $blueFour;

            }
        }

        .menu-container-closed,
        .menu-container-open {
            display: block;

            .animation-container {
                display: none;
            }

            ol {
                display: flex;
                flex-direction: row;

                list-style: none;

                width: 50vw;

                margin-top: 36px;
                margin-left: 24px;

                li {
                    margin-right: 64px;

                    a {


                        p {
                            color: $blueFour;
                            text-transform: uppercase;

                            &:hover {
                                color: $blueThree;
                            }

                        }
                    }
                }

                :last-child {
                    margin-right: 0;
                }
            }

        }

    }
}