@import "../../style/basic/config.module";
@import"include-media";


$breakpoints: ('phone': 300px,
    'tablet': 768px,
    'desktop': 1024px,
) !default;



@include media(">20px") {

    .copyright-bar {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        margin-top: 32px;
        margin-left: 24px;
        margin-bottom: 12px;


        p {
            font-size: 12px;
            font-weight: 600;
            color: $blueFour;

            a {
                text-decoration: none;
                color: $blueFour;



            }
        }
    }

}

@include media(">=tablet") {

    .copyright-bar {

        margin-left: 94px;
        margin-bottom: 24px;

        p {
            font-size: 16px;
        }
    }

}

@include media(">=desktop") {

    .copyright-bar {

        margin-left: 128px;
        // margin-bottom: 24px;

        p {
            font-size: 16px;
        }
    }

}