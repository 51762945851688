@import "../../style/basic/config.module";
@import"include-media";


$breakpoints: ('phone': 300px,
    'tablet': 768px,
    'desktop': 1024px,
) !default;


@include media(">20px") {
    .next-page-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        margin-top: 64px;
        padding: 0 24px;

        a {
            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;
            color: $blueFour;

            display: flex;
            flex-direction: row;
            align-items: center;


            .icon.show {
                margin: 0 4px;
            }

            .icon.hide {
                display: none;
            }

        }
    }
}



@include media(">=tablet") {


    .next-page-container {
        padding: 0 96px;


        a {
            font-size: 24px;



            // .icon {}

        }
    }

}

@include media(">=desktop") {


    .next-page-container {
        padding: 0 192px;


        // a {
        //     // font-size: 24px;



        //     // .icon {}

        // }
    }

}