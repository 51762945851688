@import "../../../style/basic/config.module";
@import"include-media";


$breakpoints: ('phone': 300px,
    'tablet': 768px,
    'desktop': 1024px,
) !default;



@include media(">20px") {
    .checklist-grid-container {
        margin-top: 64px;


        .next-page-container {
            margin-top: 64px;
        }

    }
}


@include media(">desktop") {
    .checklist-grid-container {
        margin: 64px auto;



        .next-page-container {
            margin-top: 192px;
        }

    }
}