@import "../../../../style/basic/config.module";
@import"include-media";


$breakpoints: ('phone': 300px,
    'tablet': 768px,
    'desktop': 1024px,
) !default;



@include media(">20px") {
    .design-text-chapter {
        margin-top: 32px;
        padding: 0 24px;
        color: $blueFour;

        h2 {
            font-size: 24px;
            font-weight: 700;
            text-align: left;
            text-transform: uppercase;

            margin-bottom: 12px;
        }

        p {
            font-size: 16px;
            font-weight: 600;
        }

        p+p {
            margin-top: 16px;
        }


        li::marker {
            font-size: 80%;
        }



        h3 {
            font-size: 16px;
            font-weight: 700;
            // text-align: center;
            margin-top: 32px;
        }

        .ol-doku {

            list-style: none;
            text-align: left;

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;



            li {
                flex-basis: 50%;

                margin-top: 32px;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;


                p {}

                .animation-container {
                    width: 96px;
                }
            }
        }

        .systems-wrap {
            background-color: $blueFour;
            padding: 32px 24px;
            border-radius: 15px;

            margin: 64px auto;



            .design-systems {


                h4 {
                    text-transform: uppercase;
                    text-align: center;
                    color: $white;
                }


                ol {

                    // list-style: none;
                    // text-align: center;

                    // display: flex;
                    // flex-direction: row;
                    // flex-wrap: wrap;
                    // justify-content: center;
                    // align-items: flex-start;

                    list-style: none;
                    text-align: center;
                    margin-top: 8px;


                    li {
                    //     width: 100%;
                    //     flex-basis: 50%;

                    // align-self: baseline;


                    //     margin-top: 32px;
                    //     line-height: 1.3;

                    margin-top: 32px;
                    line-height: 1.3;

                        
                a{                                                        
                    display: block;
                    min-height: 128px;


                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;


                    color: $greyTwo;
                    font-weight: 600;

                    // text-decoration: underline;

                    background-color: $blueThree;
                    border-radius: 10px;

                    padding: 0 12px;

                            div{ 
                                font-weight: 700;
                                margin-bottom: -20px;
                                color: $white;   
                            }

                            &:hover{
                                color: $white;
                                background-color: lighten($blueThree, 4%);
                            }

                        }
                       
                    }

                }
            }
        }

    }

    .tools-container {
        .icon-section {

            display: flex;
            flex-direction: row;
            justify-content: center;

            margin-top: 32px;

            a {
                display: block;
                margin-right: 24px;

                background-color: $greyOne;
                // padding: 24px;
                padding: 16px;
                border-radius: 10px;


                transform: scale(1);

                transition: background-color 0.6s cubic-bezier(0.25, 1, 0.5, 1),
                    transform 0.6s cubic-bezier(0.25, 1, 0.5, 1);

                svg {
                    width: 48px;
                    height: 48px;
                }

                &:last-child {
                    margin-right: 0;
                }

                &:hover {
                    background-color: $greyTwo;
                    transform: scale(1.05);
                }

            }


        }
    }

    .modern-principles-container {
        ol {

            background-color: $blueFour;
            padding: 32px 24px;
            border-radius: 15px;

            margin: 32px auto;

            list-style: disc;

            li {
                margin-left: 24px;
                margin-top: 16px;
                color: $white;
            }
        }
    }
}


@include media(">phone") {

    .tools-container {
        .icon-section {
            a {
                padding: 24px;
            }
        }
    }
}

@include media(">=tablet") {

    .design-text-chapter {
        margin: 64px auto;
        width: 90%;

        h2 {
            font-size: 48px;
            // text-align: left;
        }

        p {
            font-size: 24px;


            display: block;
            margin: 0 auto;
        }

        p+p {
            margin-top: 32px;
        }



        h3 {
            font-size: 24px;
            margin-top: 64px;
        }


        .ol-doku {
            font-size: 24px;

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

            // width: 80%;

            align-self: center;



            li {
                width: 100%;
                flex-basis: 50%;
                margin-top: 64px;

                .animation-container {
                    width: 128px;
                }

            }
        }

        .systems-wrap {
            padding: 64px;

            width: 100%;

            display: flex;
            flex-direction: column;
            justify-content: center;

            .design-systems {

                h4 {
                    font-size: 32px;
                }

                ol {
                    width: 85%;
                    margin: 0 auto;
                    font-size: 24px;

                    li {
                        // flex-basis: 1;
                    margin-top: 64px;

                    a{
                        min-height: 300px;
                        border-radius: 20px;
                        padding: 24px;
                        div{
                            margin-bottom: -28px;

                        }
                    }
                    }
                }
            }
        }
    }


    .tools-container {
        .icon-section {



            margin-top: 32px;

            a {

                margin-right: 32px;
                padding: 24px;


                svg {
                    width: 96px;
                    height: 96px;
                }

            }


        }
    }

    .modern-principles-container {
        ol {
            padding: 96px 64px;
            font-size: 24px;
            width: 100%;


            // margin-top: 32px;
            // margin-left: 0;


            li:first-child {
                margin-top: 0;
            }
        }
    }

}

@include media(">=desktop") {

    .design-text-chapter {
        margin: 96px auto;
        width: 70%;

        h2 {
            // font-size: 48px;
            // text-align: left;
        }

        p {
            // font-size: 24px;


            // display: block;
            // margin: 0 auto;
        }

        p+p {
            // margin-top: 32px;
        }



        h3 {
            font-size: 32px;
            margin-top: 96px;
            text-align: center;
        }

        .ol-doku {
            // font-size: 24px;

            // display: flex;
            // flex-direction: row;
            // flex-wrap: wrap;
            // justify-content: flex-start;

            // width: 80%;

            // align-self: center;

            li {
                // width: 100%;
                flex-basis: 15%;
                margin-right: 24px;
                align-self: baseline;

                .animation-container {
                    width: 128px;
                    margin-top: 16px;

                }

                &:last-child {
                    margin-right: 0;
                }

                p {
                    margin-top: 16px;
                    text-align: center;
                }

            }
        }

        .systems-wrap {
            padding: 64px 64px;

            width: 90%;

            margin-top: 64px;

            // display: flex;
            // flex-direction: column;
            // justify-content: center;
            .design-systems {
                // margin-top: 96px;

                h4 {
                    // font-size: 24px;
                }

                // ol {
                //     // width: 60%;
                //     // margin: 0 auto;
                //     // font-size: 24px;

                //     li {
                //         // flex-basis: 1;
                //     }
                // }

                ol {

                    width: 100%;

                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
    
                    justify-content: flex-start;
                    align-items: flex-start;
    
                    // display: inline-block;
                    text-align: left;
    
    
                    li {
    
                        width: 100%;
                        flex-basis: 50%;
    
                        font-size: 30px;
    
    
                        align-self: baseline;
                        padding: 24px;
    
    
                        text-align: center;
    
                        margin-top: 32px;
    
                        a{
                justify-content: baseline;
                margin: 0 auto;
    
                            div{
                               margin-bottom: -38px;
    
                            }
                        }
                    }
    
                    li:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .tools-container {


        .icon-section {

            margin-top: 64px;

            a {

                margin-right: 128px;
                padding: 24px;


                svg {
                    width: 96px;
                    height: 96px;
                }

            }


        }
    }

    .modern-principles-container {
        ol {
            // padding: 96px 64px;
            // font-size: 24px;
            width: 90%;


            // margin-top: 32px;
            // margin-left: 0;


            li:first-child {
                // margin-top: 0;
            }
        }
    }

}