//base styles
@import "./style/basic/_typo.scss";
@import "./style/basic/config.module.scss";

@import"include-media";


$breakpoints: ('phone': 300px,
    'tablet': 768px,
    'desktop': 1024px,
) !default;

@include media(">10px") {
    body {
        background-color: $white;
        overflow-x: hidden;

        ::selection {
            color: $white;
            background-color: $blueThree;
        }


        .preloader {
            background-color: $blueFour;
            width: 100vw;
            height: 100vh;
            overflow: hidden;

            .animation-container {
                position: absolute;
                transform: translate(-50%, -50%);
                top: 50%;
                left: 50%;
                width: 300px;
                height: 300px;
            }
        }

        .cookie-container {
            .cookie-content-container {}

            div {
                display: block;
                margin: 0 auto;
                align-self: center;
            }
        }
    }

}