$blueFour: #003356;
$blueThree: #005899;
$blueTwo: #40C4ED;
$blueOne: #A6ECFF;

$white: #F4F4F4;
$yellow: #F2D766;

$greyFour: #464949;
$greyThree: #818889;
$greyTwo: #B9C4C6;
$greyOne: #DAE4E5;




:export {
    blueFour: $blueFour;
    blueThree: $blueThree;
    blueTwo: $blueTwo;
    blueOne: $blueOne;

    white: $white;
    yellow: $yellow;

    greyFour: $greyFour;
    greyThree: $greyThree;
    greyTwo: $greyTwo;
    greyOne: $greyOne;
}