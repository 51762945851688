@import "../../style/basic/config.module";
@import"include-media";

$breakpoints: ('phone': 300px,
    'tablet': 768px,
    'desktop': 1024px,
) !default;



@include media(">20px") {
    .checklist-container {
        // width: 70%;

        .list-item {
            margin: 32px 24px;

            .title-wrap {
                display: flex;
                flex-direction: row;
                align-items: center;

                .checkbox {
                    cursor: pointer;

                    width: 24px;
                    height: 24px;
                    margin-right: 12px;
                }

                .item-title {
                    text-transform: uppercase;
                    font-size: 24px;
                    font-weight: 700;
                    color: $blueFour;
                }

            }



            .info-container {

                display: flex;
                flex-direction: column;
                justify-content: flex-start;


                border: 2px solid $blueFour;
                border-radius: 10px;
                min-height: 96px;


                margin: 0;
                margin-top: 8px;
                // padding-bottom: 4px;

                color: $blueFour;
                font-size: 16px;
                font-weight: 700;

                padding: 0;

                height: auto;


                .subtitle-wrap {

                    cursor: pointer;

                    display: flex;
                    flex-direction: row;
                    align-self: center;
                    justify-content: flex-start;

                    //!!====
                    padding: 24px 16px 24px 24px;
                    // border-radius: 7px 7px 0 0;

                    border-radius: 10px;


                    width: 100%;


                    position: relative;

                    .title-BG {
                        z-index: -10;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background-size: 100%;

                        transform: translate(50%, 50%);
                        top: 0%;
                        left: 0%;

                        padding: 0;
                        margin: 0;


                    }


                    .arrow {
                        margin-right: 10px;
                        width: 36px;
                        height: 36px;
                        margin-top: 8px;
                    }

                    .item-subtitle {


                        align-self: center;
                        margin-top: -8px;

                    }

                }



                .content-wrapper {
                    margin: 12px 24px 23px 32px;
                    color: $blueFour;

                    font-size: 16px;
                    font-weight: 600;


                    // padding-left: 32px;
                    // padding-right: 24px;
                    padding: 0 24px 0 32px;

                    cursor: default;

                    display: block;

                    p{
                        a{
                            color: $blueFour;
                            text-decoration: underline;
                        }
                    }


                    .list-wrap {
                        margin-top: 12px;

                        li {
                            margin-left: 16px;
                        }
                    }


                }
            }
        }
    }
}


@include media(">phone") {
    .checklist-container {
        // width: 70%;

        .list-item {
            margin: 32px 24px;

            .info-container {

                .subtitle-wrap {


                    //!!====
                    padding: 24px 48px 24px 32px;

                }
            }
        }
    }
}




@include media(">=tablet") {
    .checklist-container {

        width: 70%;
        max-width: 1080px;
        margin: 0 auto;

        .list-item {

            margin-top: 96px;

            .title-wrap {

                .checkbox {
                    transform: scale(1.5);
                    margin-right: 16px;
                    margin-left: 4px;
                }

                .item-title {
                    font-size: 48px;
                }

            }

            .info-container {
                display: block;

                color: $blueFour;
                font-size: 24px;

                margin-top: 16px;

                border-width: 3px;

                .subtitle-wrap {

                    padding: 32px 32px;

                    align-items: center;


                    .arrow {
                        width: 36px;
                        height: 36px;
                        margin-right: 16px;
                        margin-bottom: 8px;

                    }
                }




                .content-wrapper {

                    margin-top: 24px;
                    margin-left: 50px;
                    margin-bottom: 32px;

                    font-size: 24px;

                    padding-right: 64px;
                }


                .content-wrapper.open {
                    margin-top: 24px;


                    p {}

                    .list-wrap {
                        margin-top: 24px;

                        li {
                            margin-left: 32px;
                        }
                    }


                }
            }
        }
    }
}


//====================================IPHONE SE && smaller========================================



// @include media(">20px", "<desktop") {
//     .checklist-container {
//         // width: 70%;

//         .list-item {
//             margin: 32px 24px;

//             .title-wrap {
//                 display: flex;
//                 flex-direction: row;
//                 align-items: center;

//                 .checkbox {
//                     cursor: pointer;

//                     width: 24px;
//                     height: 24px;
//                     margin-right: 12px;
//                 }

//                 .item-title {
//                     text-transform: uppercase;
//                     font-size: 24px;
//                     font-weight: 700;
//                     color: $blueFour;
//                 }

//             }



//             .info-container {

//                 display: flex;
//                 flex-direction: column;
//                 justify-content: space-around;


//                 border: 2px solid $blueFour;
//                 border-radius: 10px;
//                 min-height: 96px;

//                 margin-top: 8px;
//                 // padding-bottom: 4px;

//                 color: $blueFour;
//                 font-size: 16px;
//                 font-weight: 700;

//                 .subtitle-wrap {

//                     cursor: pointer;

//                     display: flex;
//                     flex-direction: row;
//                     align-self: center;
//                     justify-content: flex-start;


//                     padding: 24px 48px 24px 32px;
//                     border-radius: 7px 7px 0 0;

//                     width: 100%;


//                     .arrow {
//                         margin-right: 10px;
//                         width: 36px;
//                         height: 36px;
//                         margin-top: 8px;
//                     }

//                     .item-subtitle {

//                         align-self: center;
//                         margin-top: -8px;
//                         background-color: red;

//                     }

//                 }

//                 .subtitle-wrap.background-off {
//                     background-color: transparent;
//                 }

//                 .subtitle-wrap.background-on {
//                     background-color: $blueFour;
//                     color: $white;

//                 }



//                 .content-wrapper {
//                     margin: 12px 24px 23px 32px;
//                     color: $blueFour;

//                     font-size: 16px;
//                     font-weight: 600;


//                 }

//                 .content-wrapper.closed {
//                     display: none;
//                 }

//                 .content-wrapper.open {
//                     display: block;
//                     padding-left: 32px;
//                     padding-right: 24px;


//                     cursor: default;


//                     .list-wrap {
//                         margin-top: 12px;

//                         li {
//                             margin-left: 16px;
//                         }
//                     }


//                 }
//             }
//         }
//     }
// }