@import "../../../../style/basic/config.module";
@import"include-media";


$breakpoints: ('phone': 300px,
    'tablet': 768px,
    'desktop': 1024px,
) !default;


@include media(">20px") {

    .text-chapter {
        margin-top: 64px;
        padding: 0 24px;
        color: $blueFour;

        h2 {
            font-size: 24px;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;

            margin-bottom: 12px;
        }

        p {
            font-weight: 700;
        }

        section {
            margin-top: 64px;


            h3 {
                font-size: 16px;
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: 4px;
            }

            p {
                font-size: 16px;
                font-weight: 600;
            }

            p+p {
                margin-top: 16px;
            }

            //!!VISUAL GUIDANCE

            .feedback-lottie {
                margin-top: 32px;
                margin-bottom: -32px;

                .lottie {}
            }

            .context-lottie {
                margin-top: 32px;
                margin-bottom: -32px;


                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .lottie {
                    width: 150px;
                    height: 150px;
                }
            }

            .loader-lottie {
                margin-top: 32px;
                margin-bottom: -32px;

                .lottie {
                    width: 280px;
                    height: 200px;
                }
            }

            //!!ATTENTION
            .cone-of-vision-lottie {
                margin-top: 32px;
                margin-bottom: -32px;

                .lottie {}
            }

            .user-education-lottie {
                height: 350px;
                width: 200px;

                display: block;
                margin: 32px auto;
                margin-bottom: 32px;

                .lottie {}
            }

            .error-handling-lottie {
                height: 300px;
                width: 200px;

                display: block;
                margin: 32px auto;

                .lottie {}
            }

        }
    }

    .attention-container {
        margin-top: 96px;
    }

    .personality-container {
        margin-top: 128px;
    }
}


@include media(">=tablet") {

    .text-chapter {
        margin: 64px auto;
        width: 90%;

        .chapter-header {
            margin-bottom: 64px;

            h2 {
                font-size: 48px;
                // text-align: left;
            }

            p {
                font-size: 24px;

                // width: 95%;

                display: block;
                margin: 0 auto;
            }
        }

        section {
            margin-top: 64px;

            .container {
                display: flex;
                flex-direction: column;
            }

            .reverse {
                flex-direction: column;
            }

            h3 {
                font-size: 24px;
            }

            p {
                font-size: 24px;
            }

            p+p {
                margin-top: 24px;
            }

            .feedback-lottie {
                width: 500px;
                height: 150px;
                display: block;
                margin: 32px auto;

                align-self: center;
            }

            .context-lottie {
                width: 500px;
                height: 300px;

                margin: 32px auto;
            }

            .loader-lottie {
                display: block;
                margin: 64px auto;
            }

            //!!ATTENTION
            .cone-of-vision-lottie {
                width: 500px;
                height: 500px;
                display: block;
                margin: 0px auto;
                // margin-left: 128px;

                align-self: center;
            }

            .user-education-lottie {
                width: 300px;
                height: 550px;
                display: block;
                margin: 32px auto;


                align-self: center;

            }

            .error-handling-lottie {
                width: 300px;
                height: 550px;
                display: block;
                margin: 32px auto;


                align-self: center;
            }

        }
    }

    .attention-container {
        margin-top: 128px;
    }
}

@include media(">=desktop") {

    .text-chapter {
        // margin: 64px auto;
        width: 70%;

        .chapter-header {
            // margin-bottom: 64px;

            h2 {
                // font-size: 48px;
                // text-align: left;
            }

            p {
                // font-size: 24px;

                // width: 95%;

                // display: block;
                // margin: 0 auto;
            }
        }

        section {
            // margin-top: 64px;

            .container {
                // display: flex;
                flex-direction: row;
            }

            .reverse {
                flex-direction: row-reverse;
            }

            h3 {
                // font-size: 24px;
            }

            p {
                // font-size: 24px;
            }

            p+p {
                // margin-top: 24px;
            }

            .feedback-lottie {
                width: 2000px;
                height: 250px;
                // display: block;
                margin: 0 auto;

                // align-self: center;
            }

            .context-lottie {
                width: 800px;
                height: 400px;

                // margin: 32px auto;
            }

            .loader-lottie {
                // display: block;
                // margin: 64px auto;
            }

            //!!ATTENTION
            .cone-of-vision-lottie {
                width: 2000px;
                height: 550px;
                // display: block;
                margin: -32px auto;
                margin-left: 128px;

                // align-self: center;
            }

            .user-education-lottie {
                width: 1000px;
                height: 550px;
                // display: block;
                margin: 0 auto;

                margin-right: 128px;

                // align-self: center;

                flex-grow: 4;
            }

            .error-handling-lottie {
                width: 1000px;
                height: 650px;
                // display: block;
                margin: 0 auto;

                margin-left: 128px;

                // align-self: center;
            }

        }
    }

    .personality-container {
        margin-top: -128px;
    }
}