@import "../../../../../style/basic/config.module";
@import"include-media";


$breakpoints: ('phone': 300px,
    'tablet': 768px,
    'desktop': 1024px,
) !default;



@include media(">20px") {
    .share-container {
        margin-top: 64px;

        h3 {
            font-size: 24px;
            font-weight: 700;
            text-transform: uppercase;
            text-align: center;
            color: $blueFour;
        }

        .social-icons {
            display: flex;
            flex-direction: row;
            justify-content: center;


            margin-top: 16px;

            .share-wrap {
                margin-right: 16px;
            }

            :last-child {
                margin-right: 0;
            }

        }
    }
}


@include media(">=tablet") {
    .share-container {
        margin-top: 128px;

        h3 {
            font-size: 48px;
        }

        .social-icons {
            margin-top: 32px;

            .share-wrap {
                margin-right: 64px;

                .icon {
                    width: 96px;
                    height: 96px;
                }
            }

            :last-child {
                margin-right: 0;
            }

        }
    }
}


@include media(">=desktop") {
    .share-container {
        // margin-top: 128px;

        h3 {
            // font-size: 48px;
        }

        .social-icons {
            // margin-top: 32px;

            .share-wrap {
                // margin-right: 64px;

                .icon {
                    width: 128px;
                    height: 128px;
                }
            }

            :last-child {
                // margin-right: 0;
            }

        }
    }
}