@import "../../../style/basic/config.module";
@import"include-media";

$breakpoints: ('phone': 300px,
    'tablet': 768px,
    'desktop': 1024px,
) !default;



@include media(">=20px") {
    .properties-grid-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0 24px;


        .animation-wrap {
            width: 128px;
            margin-top: 48px;



            .animation-container {
                height: 128px;
                width: 128px;

            }

            .blur {
                height: 128px;
                width: 128px;
            }

            .depth {
                position: relative;
                height: 128px;
                width: 128px;
                background-color: $white;
            }

            p {
                color: $blueFour;
                font-weight: 700;
                text-align: center;
                margin-top: 8px;
            }
        }

    }
}





@include media(">=tablet") {
    .properties-grid-container {

        padding: 0 96px;

        margin-top: 96px;

        .animation-wrap {
            width: 128px;
            // margin-top: 128px;
            margin: 24px auto;
            // margin-right: 16px;


            .animation-container {
                height: 128px;
                width: 128px;
            }

            .blur {
                height: 128px;
                width: 128px;
            }

            .depth {
                // background-color: red;

            }

            p {

                padding: 0;
            }



        }



    }
}

@include media(">=desktop") {
    .properties-grid-container {

        padding: 0 128px;
        margin-top: 0;

        .animation-wrap {
            width: 192px;
            margin-top: 128px;
            // margin-right: 64px;

            .animation-container {
                height: 192px;
                width: 192px;
            }

            .blur {
                height: 192px;
                width: 192px;
            }

            .depth {}

            p {
                // padding: 0;
            }
        }

    }
}

//!!====================================IPHONE SE && smaller========================================

// @include media(">20px", "<desktop") {
//     .properties-grid-container {
//         display: flex;
//         flex-direction: row;
//         flex-wrap: wrap;
//         justify-content: space-around;
//         padding: 0 24px;


//         .animation-wrap {
//             width: 128px;
//             margin-top: 48px;


//             .animation-container {
//                 height: 128px;
//                 width: 128px;

//             }

//             .blur {
//                 height: 128px;
//                 width: 128px;
//             }

//             .depth {
//                 position: relative;
//                 background-color: $white;
//             }

//             p {
//                 color: $blueThree;
//                 font-weight: 700;
//                 text-align: center;
//                 margin-top: 8px;
//             }
//         }

//     }
// }