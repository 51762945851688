@import "../../style/basic/config.module";
@import"include-media";


$breakpoints: ('phone': 300px,
    'tablet': 768px,
    'desktop': 1024px,
) !default;



@include media(">20px") {

    header {
        z-index: -10;
        position: relative;
        width: 100%;
        height: 130px; //same size as logo
        overflow: hidden;


        .headline-wrap {
            position: absolute;
            z-index: 10;

            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;

            h1 {
                font-size: 36px;
                font-weight: 700;
                color: $blueFour;
                text-align: center;
                text-transform: uppercase;

            }
        }

        .icon-wrap {
            position: relative;
            z-index: 10;

            .icon {
                display: none;

            }
        }

        .arrow-scroll {
            z-index: 10;
            display: none;
        }

        .logo-wrap {
            position: absolute;
            transform: translate(-50%, -50%);

            left: 50%;
            top: 50%;

            width: 256px;
            height: 130px;

            .logo {

                width: 256px;
                height: 130px;

                display: block;
                margin: 0 auto;
            }
        }
    }
}


@include media(">=tablet") {

    header {
        height: 500px;


        .icon-wrap {
            .icon {
                display: block;
                width: 128px;
                height: 128px;

                position: absolute;
                transform: translate(-50%, -50%);
                left: 50%;
                top: 30%;
            }
        }

        .headline-wrap {
            z-index: 100;
            top: 50%;

            h1 {
                margin-top: 16px;
                font-size: 70px;
            }
        }



        .arrow-scroll {
            display: block;
            width: 64px;
            height: 64px;

            position: absolute;
            transform: translate(-50%, -50%);
            left: 50%;
            top: 70%;
        }



        .logo-wrap {

            // top: 250px;

            top: 246px;

            width: 1000px;
            height: 500px;

            .logo {

                width: 2000px;
                height: 500px;
            }
        }
    }

}

@include media(">=desktop") {

    header {
        height: 1000px;

        .icon-wrap {
            .icon {
                // display: block;
                width: 256px;
                height: 256px;

                // position: absolute;
                // transform: translate(-50%, -50%);
                // left: 50%;
                // top: 30%;
            }
        }

        .headline-wrap {
            // z-index: 100;

            h1 {
                margin-top: 96px;
                font-size: 100px;
            }
        }


        .arrow-scroll {
            // display: block;
            width: 96px;
            height: 96px;

            // position: absolute;
            // transform: translate(-50%, -50%);
            // left: 50%;
            top: 68%;
        }



        .logo-wrap {

            // top: 500px;
            top: 492px;


            width: 2000px;
            height: 1000px;

            .logo {

                width: 4000px;
                height: 1000px;
            }
        }
    }

}