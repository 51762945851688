@import "./config.module.scss";
@import"include-media";



$breakpoints: ('phone': 300px,
    'tablet': 768px,
    'desktop': 1024px,
) !default;


a {
    text-decoration: none;
}




@include media(">phone", "<500px") {
    body {
        background-color: $blueTwo;

        h1 {
            font-size: 36px;
            line-height: 39px;
            font-weight: 700;
        }

        h2 {
            font-size: 24px;
            line-height: 36px;
            font-weight: 700;
        }


        p {
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
        }

    }
}

@include media(">desktop") {
    body {
        h2 {
            font-size: 36px;
            line-height: 54px;
            font-weight: 700;
        }


        p {
            font-size: 24px;
            line-height: 36px;
            font-weight: 600;
        }

    }
}