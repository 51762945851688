@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600;700&display=swap');


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font: 100%/1.5 'Open Sans', sans-serif;
  font-weight: 400;
  text-align: left;
  font-display: swap;

  overflow-x: hidden;


}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}