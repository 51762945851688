@import"include-media";


$breakpoints: ('phone': 300px,
    'tablet': 768px,
    'desktop': 1024px,
) !default;

@include media(">10px", "<601px") {
    .hover-nav {
        position: relative;
        transition: 0.3s all ease-in-out;


        &::before {
            content: '';
            position: absolute;
            bottom: -3px;
            right: 0;
            width: 0;
            height: 6px;
            background-color: $white;
            transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
        }

        &:hover {
            color: $blueThree;
            transform: scale(1.05);


            &::before {
                left: 0;
                right: auto;
                width: 100%;
            }
        }

    }
}

@include media(">600px") {
    .hover-nav {
        position: relative;
        transition: 0.3s all ease-in-out;

        p {
            transition: 0.3s all ease-in-out;
        }


        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            width: 0;
            height: 3px;
            background-color: $blueThree;
            transition: width 0.4s cubic-bezier(0.25, 1, 0.5, 1);
        }

        &:hover {
            color: $blueThree;
            // transform: translateY(-3px);
            transform: scale(1.05);

            &::before {
                left: 0;
                right: auto;
                width: 100%;
            }

            p {
                color: $blueThree;
            }
        }

    }
}


.hover-card-links{
    transform: scale(1);
    transition: all .3s ease-in-out;


    
    &:hover{
        transform: scale(1.02);
        background-color: $blueThree;
    }
}


.hover-link {
    position: relative;
    transition: 0.3s all ease-in-out;

    .icon-stroke {
        transition: 0.3s all ease-in-out;

    }


    // width: fit-content;

    &::before {
        content: '';
        position: absolute;
        bottom: -5px;
        right: 0;
        width: 0;
        height: 3px;
        background-color: $blueTwo;
        transition: width 0.4s cubic-bezier(0.25, 1, 0.5, 1);
    }

    &:hover {
        // color: $blueTh;
        // transform: translateY(-3px);
        transform: scale(1.05);

        .icon-stroke {
            stroke: $white;
        }

    }

}

.hover-icon {
    transition: 0.3s all ease-in-out;

    circle {
        transition: 0.3s all ease-in-out;

    }

    &:hover {
        transform: scale(1.03);
        // fill: red;

        circle {
            fill: $blueFour;
        }

    }
}


.hover-next {
    transition: 0.3s all ease-in-out;

    .icon {
        transition: 0.5s all ease-in-out;

        path {
            transition: 0.3s all ease-in-out;
        }
    }

    &:hover {
        transform: scale(1.05) translateX(+0px);
        color: $blueThree;

        .icon {
            transform: translateX(+10px);

            path {
                stroke: $blueThree;
            }
        }
    }
}

.hover-previous {
    transition: 0.3s all ease-in-out;

    .icon {
        transition: 0.5s all ease-in-out;

        path {
            transition: 0.3s all ease-in-out;
        }
    }

    &:hover {
        transform: scale(1.05) translateX(+0px);
        color: $blueThree;

        .icon {
            transform: translateX(-10px);


            path {
                stroke: $blueThree;
            }

        }
    }
}



.hover-card {
    transition: 0.3s all ease-in-out;

    .arrow {
        transition: 0.5s all ease-in-out;

        path {
            transition: 0.3s all ease-in-out;

        }
    }

    &:hover {

        transform: translateX(+2px);


        .arrow {
            transform: translateX(+7px);

            path {
                stroke: $greyTwo;
            }
        }
    }
}