@import "../../../style/basic/config.module";
@import"include-media";


$breakpoints: ('phone': 300px,
    'tablet': 768px,
    'desktop': 1024px,
) !default;



@include media(">20px") {
    .home-grid-container {
        padding-top: 64px;
        position: relative;

        overflow-x: hidden;



        .header-bg-wrap {
            z-index: -10;

            position: absolute;
            transform: translate(-50%, -50%);

            left: 50%;
            top: 170px;

            //!============
            width: 600px;
            height: 300px;


            // .header-bg {

            //     width: 600px;
            //     height: 400px;

            //     display: block;
            //     margin: 0 auto;
            // }


        }

        .home-intro-text {
            font-size: 24px;
            line-height: 36px;
            font-weight: 700;
            color: $blueFour;
            padding: 0 32px;
        }

        .menu-cards {
            margin-top: 64px;
            margin-bottom: 64px;
        }

        .home-text {
            font-size: 16px;
            font-weight: 600;
            color: $blueFour;
            padding: 0 24px;
        }

        .logo-trenner {
            display: block;
            margin: 48px auto;
        }


    }
}



@include media(">=tablet") {



    .home-grid-container {

        padding-top: 128px;

        .header-bg-wrap {
            // top: 500px;

            //!========
            top: 100px;

            width: 1200px;
            height: 150px;


            // .header-bg {
            //     width: 1920px;
            //     height: 1080px;
            // }
        }

        .home-intro-text {
            font-size: 36px;
            width: 80%;
            margin: 0 auto;
            line-height: 54px;
        }

        .home-text {
            font-size: 28px;
            // line-height: 1,3;
            padding: 0 96px;
        }

        .logo-trenner {
            width: 600px;
            height: 240px;
            margin: 96px auto;
        }
    }

}

@include media(">=desktop") {



    .home-grid-container {

        padding-top: 256px;

        .header-bg-wrap {
            // top: 550px;

            //!========

            top: 150px;
            height: 200px;
            width: 2000px;

            // .header-bg {
            //     width: 1920px;
            //     height: 1080px;
            // }
        }

        .home-intro-text {
            // font-size: 36px;
            width: 65%;
            // margin: 0 auto;
            // line-height: 54px;
        }

        .home-text {
            font-size: 30px;
            line-height: 1.5;
            // line-height: 54px;
            padding: 0 192px;
        }

        .logo-trenner {
            width: 700px;
            height: 340px;
            // margin: 96px auto;
        }
    }

}