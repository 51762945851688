@import "../../../style/basic/config.module";
@import"include-media";

$breakpoints: ('phone': 300px,
    'tablet': 768px,
    'desktop': 1024px,
) !default;


@include media(">20px") {
    .personality-grid-container {
        display: flex;
        flex-direction: column;

        margin: 0 auto;


        .animation-wrap {
            width: 256px;
            margin: 0 auto;

            margin-top: 32px;


            .animation-container {}

            p {
                color: $blueFour;
                font-weight: 700;
                text-align: center;
                margin-top: 12px;
            }
        }

    }
}



@include media(">=tablet") {
    .personality-grid-container {

        flex-direction: row;
        flex-wrap: wrap;

        width: 80%;


        .animation-wrap {
            margin-top: 96px;
            // margin-right: 96px;

        }

        p {
            padding: 0;
        }

    }
}


@include media(">=desktop") {
    .personality-grid-container {

        // flex-direction: row;
        // flex-wrap: wrap;

        width: 100%;


        .animation-wrap {
            margin-top: 32px;
            margin-right: 0px;

        }

        p {
            // padding: 0;
        }

    }
}